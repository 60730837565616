* {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}

.btn1 {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 30px;
  font-size: 11px;
  /* text-transform: uppercase; */
  font-weight: 700;
  border: none;
  /* letter-spacing: .05rem; */
}

.btn1.btn-primary {
  background: #26ACE2;
  border-color: #26ACE2;
}

.nav_icon li {
  color: #26ACE2;
}



@media (max-width: 990px) {
  .header_box {
    top: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  .header_box {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1f2732;
    position: relative;
  }

  .header_box h1 {
    font-size: 2rem;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
  }

  .header_box a {
    list-style: none;
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
  }

  .nav_icon {
    width: 700px;
    display: flex;
    justify-content: space-around;
  }

  .header_box h1 {
    font-size: 1rem;
  }

  .header_box .nav_icon {
    display: flex;
    flex-direction: column;
    z-index: 99999;
  }

  .menu_btn {
    display: block !important;
  }

  .nav_icon {
    position: absolute;
    top: 80px;
    left: -784px;
    width: 100%;
    height: 88vh;
    background-color: rgba(0, 0, 0, 0.78);
    text-align: center;
    transition: all 0.7s ease;
  }

  .mobile {
    position: absolute;
    top: 85px;
    ;
    left: 0px;
    width: 100% !important;
    height: 93vh;
    padding: 60px;
    background-color: #fff;
    z-index: 9999;
  }

  .mobile a {
    font-weight: 600;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .nav_icon a {
    color: #fff;
  }
}

.header_box {
  height: 85px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: fixed;
  width: 100%;
  top: 40px;
  z-index: 3;
  border-bottom: 2px solid #26ACE2;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px; */
}

li {
  list-style: none;
}

.header_box h1 {
  font-size: 1.6rem;
  font-weight: 300;
  color: #26ACE2;

}

.header_box a {
  list-style: none;
  text-decoration: none;
  color: #37423b;
  font-size: 0.9rem;
  font-weight: bold;
}

.header-content h1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3px;
  white-space: 2px;
}

.header-content h2 {
  color: #eee;
  margin: 10px 0 0 0;
  font-size: 1.5rem;
  font-family: "Raleway", sans-serif;
}

.nav_icon {
  width: 600px;
  display: flex;
  justify-content: space-around;
}

.menu_btn {
  display: none;
}


.btn.btn-sm {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.btn.btn-primary {
  background: #26ACE2;
  border-color: #26ACE2;
}

.btn {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  /* border-radius: 30px; */
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  /* letter-spacing: .05rem; */
}

.logo {
  font-size: 24px;
  color: #ffffff;
  font-weight: 700;
  left: 15px;
}

.bg-2 {
  position: absolute;
  height: 40%;
  width: 70%;
  left: -50%;
  top: 0px;
  z-index: -1;
  background: rgba(236, 102, 31, 0.1);
}

.footer {
  /* background: #032D60; */
  /* background-image: url(	https://www.sgpl-aue.com/static/media/footer.516066b2da2c402ba2eb.png); */
  background-position: center;
  background-size: cover;
  font-size: 14px;
  color: #ffffff;
  padding: 23px 0;
}

.courses {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  cursor: pointer;
}

.address {
  margin-bottom: 40px;
  display: block;
  z-index: 1;
}

.icons {
  height: 30px;
  width: 30px;
  background: white;
  align-items: center;
  color: black;
  justify-content: center;
  display: flex;
  border-radius: 50%;
}

/* navbar style */
.navbarbox {
  position: fixed;
  width: 100%;
  background-color: rgba(26, 24, 22, 0.85);
  transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  top: 0;
  z-index: 1;

}

.navbar_trans {
  background-color: transparent;
  position: relative;

}

.navbar_colorChange {
  background-color: #000;
}

.vavcolor {
  color: red;

}

@media only screen and (max-width: 600px) {}




body::-webkit-scrollbar {
  width: 0.7em;
  height: 0.7em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #26ACE2;
  outline: 1px solid #26ACE2;
}


.primary-color{
  color: rgb(38, 172, 226)
 }
 
 .primary-bg-color{
   background-color: rgb(218 233 239)
  }
 
  .font-weight-900{
   font-weight: 800;
  }

  .font-weight-600{
    font-weight: 600;
  }