.multi_course p {
	font-size: 1.1rem;
}

.main_title_2 {
	text-align: center;
	margin-bottom: 45px;
	position: relative;
}

.main_title_2 span {
	width: 120px;
	height: 2px;
	background-color: #e1e1e1;
	display: block;
	margin: auto;
}

.main_title_2 span em {
	width: 60px;
	height: 2px;
	background-color: #164156;
	display: block;
	margin: auto;
}


.blog-wrapper-new {
	padding: 15px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
}




@media only screen and (max-width: 600px) {
	.about_banner {
		background-color: lightblue;
		margin-top: 84px;
	}

	.about_banner h2 {
		font-size: 30px !important;
	}


}