.mybox p {
    font-size: 1rem;
}

.mybox {
    transition: all 0.3s ease;
    border-radius: 10px;
}

.mybox i {
    font-size: 3rem;
}

/* .mybox:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.mybox:hover i {
    color: #147EFB;
} */


.mybox:hover {
    /* box-shadow: rgba(162, 180, 233, 0.35) 0px 5px 15px; */
   
    box-shadow: rgb(3 27 96 / 35%) 0px 12px 30px;
}

.mybox:hover b {
    color: #147EFB;
}

.mybox img{
    height: 120px;
    width: 200px;
}

.mybox1{
    box-shadow: rgb(3 27 96 / 35%) 0px 12px 30px;
    border-radius: 13px;
}

