.who_we h2 {
    color: #444444;
    font-size: 2.7rem;

}

.who_we p {
    color: #444444;
    font-size: 1rem;

}

.who_we hr {
    color: var(--green-color);
    height: 4px;
    width: 15%;
    transition: all 0.6s ease;
}

.who_we:hover hr {
    color: var(--green-color);
    height: 4px;
    width: 50%;
    transition: all 0.6s ease;
}