.about_banner{
    background-image:linear-gradient(to right, rgba(10, 52, 74,0.8), rgba(162, 219, 228,0.6)),url(../../images/clients/qd.jpg);
    background-color: #26ACE2;
    background-size: cover;
    background-position: center;
    height: 30vh;
    border-bottom: 5px solid #000000;
    margin-top: 20vh;
}

.about_banner h2{
    font-size: 3rem;
}


.main_title_3 {
	text-align: center;
	margin-bottom: 45px;
	position: relative;
}
.main_title_3 span {
	width: 90px;
	height: 2px;
	/* background-color: #e1e1e1; */
	display: block;
	margin: auto;
}
.main_title_3 span em {
	width: 90px;
	height: 5px;
    border-radius: 10px;
	background-color: #000000;
	display: block;
	margin: auto;
}
