.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;
}

.gallery img {
    width: 100%;
    border-radius: 50%;
    /* filter: grayscale(100%); */
    transition: all ease 0.5s;
}

.gallery img:hover {
    filter: none;
}


.photos_container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0%;
}